import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import { SectionTitle } from "../../components/Section-Title"
import header from "../../images/main-header/4.jpg"
import PageHeader from "../../components/page-header"
import OurServiceItem from "../../components/our-service-item"
import NewPatient from "../../components/new-patient"
import SEO from "../../components/seo"
// import Balancer from "react-wrap-balancer"
import { graphql } from "gatsby"
const MainContainer = styled.div`
  display: flex;
  padding: 50px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Certified = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`
const Invisalign = styled.img`
  height: 40px;
  width: auto;
  margin: 0 15px;
`
const ServicesContainer = styled.div`
  margin: 100px 0 0 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  max-width: 1000px;
  grid-gap: 20px;

  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

class DentistryPage extends React.PureComponent {
  render() {
    const serviceData = this.props.data.allWordpressPage.edges
    return (
      <Layout>
        <SEO title="Dental Services" />
        <PageHeader image={header} />
        <MainContainer>
          <Certified>
            <Invisalign src={require("../../images/Emerald-Invisalign.png")} />
            <Invisalign
              style={{
                height: "27px",
                marginBottom: "8px",
              }}
              src={require("../../images/itero.png")}
            />
          </Certified>
          <SectionTitle
            style={{
              maxWidth: "750px",
              fontSize: "24px",
              padding: "0 20px",
              lineHeight: "130%",
            }}
          >
            {/* <Balancer> */}
            We are a full service dental office providing general & costmetic
            dentistry with Emerald Certification in Invisalign Technology.
            {/* </Balancer> */}
          </SectionTitle>
          <hr style={{ width: "100%", opacity: 0.3 }} />
          <ServicesContainer>
            {serviceData.map((service, index) => (
              <OurServiceItem
                noIcon
                key={index}
                title={service.node.title}
                copy={service.node.content}
                link={`/dentistry/${service.node.slug}`}
              />
            ))}
          </ServicesContainer>
        </MainContainer>
        <NewPatient />
      </Layout>
    )
  }
}

export default DentistryPage

export const dentistryQuery = graphql`
  query($type: String = "Service") {
    allWordpressPage(
      filter: { acf: { type: { eq: $type } } }
      sort: { fields: menu_order, order: ASC }
    ) {
      edges {
        node {
          title
          content
          slug
        }
      }
    }
  }
`
